import React from 'react';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { getUrlParam } from '../../components/helpers';
import { withRouter } from 'next/router';

class Authenticator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      loading: true,
    };
  }

  async componentDidMount() {
    let { endpoint, router } = this.props;

    let cookies = new Cookies();
    let previewSecret = cookies.get('previewSecret');

    let redirects = {
      'www-vercel-preview': window.location.pathname,
      'www-vercel-authenticate': window.location.pathname,
      'vr-test': '/backstop_data/html_report/index.html',
    };

    let args = {
      'vr-test': { url: getUrlParam('url') },
    };

    try {
      let response = await axios.post(`/api/${endpoint}`, {
        ...args[endpoint],
        secret: previewSecret,
      });
      if (response.status === 200) {
        if (redirects[endpoint]) {
          window.location.replace(redirects[endpoint]);
        }
      }
    } catch (e) {
      this.setState({ isDenied: true });
    }
  }

  render() {
    let { action } = this.props;

    let insideContent = this.state.isDenied
      ? [
          <h2 style={{ marginBottom: 24 }}>Authentication Denied</h2>,
          <p style={{ fontSize: 18, lineHeight: '28px', opacity: 0.7 }}>
            Please make sure you have the proper extension installed
          </p>,
        ]
      : [
          <h2 className="ellipses-loading" style={{ marginBottom: 24 }}>
            Redirecting
          </h2>,
          <p style={{ fontSize: 18, lineHeight: '28px', opacity: 0.7 }}>
            {this.props.successMessage}
          </p>,
        ];

    return (
      <section
        style={{
          backgroundColor: '#502d3c',
          paddingTop: '40vh',
          paddingBottom: 160,
          height: '100vh',
        }}
      >
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .ellipses-loading:after {
            overflow: hidden;
            display: inline-block;
            vertical-align: bottom;
            -webkit-animation: ellipsis steps(4,end) 900ms infinite;
            animation: ellipsis steps(4,end) 900ms infinite;
            content: \"\\2026\";
            width: 0px;
          }
          
          @keyframes ellipsis {
            to {
              width: 1.25em;
            }
          }
          
          @-webkit-keyframes ellipsis {
            to {
              width: 1.25em;
            }
          }
          `,
          }}
        />
        <div style={{ width: '93%', maxWidth: 550, margin: '0 auto' }}>
          <div style={{ width: '100%', textAlign: 'center', color: '#fff' }}>
            <div className="">{insideContent}</div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(Authenticator);
