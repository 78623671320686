import SectionBuilder from '../templates/SectionBuilder';
import { returnStaticProps } from '../lib/_helpers';
import { returnTemplate } from '../lib/_client';

export default function Template(props) {
  return returnTemplate(props, SectionBuilder);
}

export async function getStaticProps(props) {
  props.params = { slug: 'home' };

  return await returnStaticProps({ ...props, type: 'page' });
}
